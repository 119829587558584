<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? DebitNote__title : ''" help-page-component="AddCommissionInvoice"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <CommissionInvoiceAlert
                    :alert="$t('message.closeAlert')"
                    mode="add"
                    type="warning"
                    class="mt-3"
                    v-if="addMode && newDebitNoteUnsaved"
                ></CommissionInvoiceAlert>
                <CommissionInvoiceAlert
                    :alert="$t('message.closeAlert')"
                    mode="update"
                    type="warning"
                    class="mt-3"
                    v-if="updateMode && updatedDebitNoteUnsaved"
                ></CommissionInvoiceAlert>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="debitNoteForm" v-model="validForm">
                        <v-overlay
                            :value="loader"
                            absolute
                            opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                    ></v-progress-circular>
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                            accordion
                            v-model="panel"
                            multiple
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <DebitNoteGeneralInfo v-if="loader"></DebitNoteGeneralInfo>
                                    <template v-if="!loader">
                                        <v-layout row pt-3 v-if="addMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.relatedContract') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                    :items="allUnInvoicedCommissionContracts"
                                                    :label="DebitNote__Contract__id ? '' : $t('message.required')"
                                                    :loading="loadingRelatedContract"
                                                    :value="DebitNote__Contract__id"
                                                    :item-text="item => [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-')"
                                                    autocomplete="off"
                                                    dense
                                                    hide-details
                                                    item-value="Contract.id"
                                                    required
                                                    solo
                                                    @click="loading.contract = true"
                                                    @blur="loading.contract = false"
                                                    @change="loadUnInvoicedContract($event)"
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                            <span class="font-sm"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                            <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                            <v-flex xs6 lg4 md6 py-1 v-if="updateMode == true">{{ DebitNote__Contract__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row pt-6 v-if="updateMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveDebitNote') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                    :items="allActiveDebitNotes"
                                                    :placeholder="$t('message.startTypingToSearch')"
                                                    dense
                                                    hide-details
                                                    item-value="DebitNote.id"
                                                    item-text="DebitNote.title"
                                                    required
                                                    solo
                                                    @change="loadAnotherActiveDebitNote($event)"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ item.DebitNote.title }}</span>
                                                            <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            <span class="font-xs"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="updateMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.relatedContract') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 v-if="updateMode == true">
                                                {{ DebitNote__Contract__title }}
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.commissionFrom') }}?</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-radio-group
                                                    :mandatory="true"
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="DebitNote__Contract__commission_term_against_id"
                                                >
                                                    <v-radio :label="$t('message.supplier')" :value="0"></v-radio>
                                                    <v-radio :label="$t('message.buyer')" :value="1"></v-radio>
                                                </v-radio-group>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.supplierInvoiceNumber') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                    :label="DebitNote__invno ? '' : $t('message.required')"
                                                    :rules="[rules.title]"
                                                    :value="DebitNote__invno"
                                                    autocomplete="off"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    @change="DebitNote__invno = $event"
                                                >
                                                </v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.debitNoteNumber') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                    :label="DebitNote__title ? '' : $t('message.required')"
                                                    :value="DebitNote__title"
                                                    :rules="[rules.title]"
                                                    autocomplete="off"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    @change="DebitNote__title = $event"
                                                >
                                                </v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.debitNoteDate') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="commissionInvoiceDateMenu"
                                                    v-model="commissionInvoiceDateMenu"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                            :label="DebitNote__dndate ? '' : $t('message.required')"
                                                            :rules="[rules.date]"
                                                            :value="computedCommissionInvoiceDateFormatted"
                                                            append-icon="event"
                                                            background-color="white"
                                                            clearable
                                                            hide-details="auto"
                                                            hint="DD/MM/YYYY format"
                                                            persistent-hint
                                                            solo
                                                            v-on="on"
                                                            @click:clear="DebitNote__dndate = null"
                                                            @click:append="commissionInvoiceDateMenu = true"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="DebitNote__dndate"
                                                        @input="commissionInvoiceDateMenu = false"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.buyer') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ DebitNote__Customer__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.supplier') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ DebitNote__Supplier__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.appTimberCompany') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ DebitNote__Office__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.posted') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="font-weight-bold">{{ DebitNote__is_posted == 1 ? $t('message.yes'): $t('message.no') }}</v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.calculation') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <DebitNoteCalculation v-if="loader"></DebitNoteCalculation>
                                    <template v-if="!loader">
                                        <v-layout row pt-3>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.commissionTerms') }}</v-flex>
                                            <v-flex xs6 lg6 md6 py-1>
                                                <v-radio-group
                                                    :mandatory="true"
                                                    :value="DebitNote__Contract__commission_term_id"
                                                    class="mt-0 align-center"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    @change="updateCommission('DebitNote__Contract__commission_term_id',$event)"

                                                >
                                                    <v-radio :label="$t('message.cfrCif')" :value="0"></v-radio>
                                                    <v-radio :label="$t('message.fob')" :value="1"></v-radio>
                                                    <v-radio :label="$t('message.exwFas')" :value="2"></v-radio>
                                                    <v-radio :label="$t('message.perUnit')" :value="3"></v-radio>
                                                </v-radio-group>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="[1].includes(DebitNote__Contract__commission_term_id)">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.freight') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <div class="d-flex flex-row align-center">
                                                    <v-text-field
                                                        :value="DebitNote__freight"
                                                        :label="DebitNote__freight > 0 ? '' : $t('message.cost')"
                                                        autocomplete="off"
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        type="number"
                                                        @change="updateCommission('DebitNote__freight',$event)"
                                                    ></v-text-field>
                                                    <span class="ml-2">{{ getCurrency(DebitNote__currency_id) }}</span>
                                                </div>
                                                <v-select
                                                    :items="containerSizes"
                                                    :value="DebitNote__containersize_id"
                                                    :label="DebitNote__containersize_id ? '' : $t('message.containerSize')"
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Containersize.title"
                                                    item-value="Containersize.id"
                                                    solo
                                                    @change="updateCommission('DebitNote__containersize_id', $event)"
                                                ></v-select>
                                                <v-text-field
                                                    :value="DebitNote__containercount"
                                                    :label="DebitNote__containercount > 0 ? '' : $t('message.containerCount')"
                                                    autocomplete="off"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    type="number"
                                                    @change="updateCommission('DebitNote__containercount', $event)"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="[1].includes(DebitNote__Contract__commission_term_id)">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.margin') + "%" }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                    :label="DebitNote__fobfee ? '' : $t('message.required')"
                                                    :rules="[rules.margin]"
                                                    :value="DebitNote__fobfee"
                                                    autocomplete="off"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    type="number"
                                                    @change="updateCommission('DebitNote__fobfee',$event)"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="[2].includes(DebitNote__Contract__commission_term_id)">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.exwFasValue') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center">
                                                <v-text-field
                                                    :label="DebitNote__fasvalue ? '' : $t('message.required')"
                                                    :value="DebitNote__fasvalue"
                                                    autocomplete="off"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    type="number"
                                                    @change="updateCommission('DebitNote__fasvalue',$event)"
                                                ></v-text-field>
                                                <span class="ml-2">{{ getCurrency(DebitNote__currency_id) }}</span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="[3].includes(DebitNote__Contract__commission_term_id)">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.quantity') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center">
                                                <v-text-field
                                                    :label="DebitNote__mbfqty ? '' : $t('message.required')"
                                                    :value="DebitNote__mbfqty"
                                                    autocomplete="off"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    type="number"
                                                    @change="updateCommission('DebitNote__mbfqty',$event)"
                                                ></v-text-field>
                                                <span class="ml-3">M3</span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="[3].includes(DebitNote__Contract__commission_term_id)">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.feePerUnit') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center">
                                                <v-text-field
                                                    :label="DebitNote__Contract__commission_unit_price ? '' : $t('message.required')"
                                                    :value="DebitNote__Contract__commission_unit_price"
                                                    autocomplete="off"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    type="number"
                                                    @change="updateCommission('DebitNote__Contract__commission_unit_price',$event)"
                                                ></v-text-field>
                                                <span class="ml-2">{{ getCurrency(DebitNote__currency_id) }}</span>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="[0,2].includes(DebitNote__Contract__commission_term_id)">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.margin') + "%" }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                    :label="DebitNote__cfrciffee ? '' : $t('message.required')"
                                                    :value="DebitNote__cfrciffee"
                                                    autocomplete="off"
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    type="number"
                                                    @change="updateCommission('DebitNote__cfrciffee',$event)"
                                                ></v-text-field>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.supplierInvoiceValue') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center">
                                                <v-text-field
                                                    :label="DebitNote__dnvalue ? '' : $t('message.required')"
                                                    :rules="[rules.debitNoteValue]"
                                                    :value="DebitNote__dnvalue"
                                                    autocomplete="off"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    type="number"
                                                    @change="updateCommission('DebitNote__dnvalue',$event)"
                                                >
                                                </v-text-field>
                                                <span class="ml-2 mr-5">{{ getCurrency(DebitNote__currency_id) }}</span>
                                                <v-select
                                                    :items="valueTypes"
                                                    :label="DebitNote__valuetype ? '' : $t('message.required')"
                                                    class="ml-2"
                                                    autocomplete="off"
                                                    dense
                                                    hide-details="auto"
                                                    required
                                                    solo
                                                    v-model="DebitNote__valuetype"
                                                >
                                                </v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.commissionAmount') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="font-weight-bold">
                                                {{ formatThisNumber(DebitNote__amount, findCurrencyPrecision(DebitNote__currency_id)) + " " + getCurrency(DebitNote__currency_id) }}
                                            </v-flex>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.finance') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.salesColleague') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>{{ DebitNote__Salescontact__name }}</v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.bankDetails') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items="filteredBankAccounts"
                                                dense
                                                item-value="Bank.id"
                                                item-text="Bank.title"
                                                hide-details
                                                solo
                                                v-model="DebitNote__bank_id"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column py-2">
                                                        <span class="font-sm">{{ item.Bank.title }}</span>
                                                        <span class="font-weight-bold"> {{ item.Bank.account }} </span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    <div class="d-flex flex-column py-0">
                                                        <span class="font-sm">{{ item.Bank.title + ':' }}</span>
                                                        <span class="font-weight-bold"> {{ item.Bank.account }} </span>
                                                    </div>
                                                </template>
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.gstVatPpnRate') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items="gstRates"
                                                :label="DebitNote__gstpercent ? '' : $t('message.required')"
                                                clearable
                                                dense
                                                hide-details
                                                item-value="Gstrate.value"
                                                item-text="Gstrate.title"
                                                solo
                                                required
                                                v-model="DebitNote__gstpercent"
                                            >
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.exchangeRateForGstVatPpn') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-text-field
                                                :label="DebitNote__gstrmrate ? '' : $t('message.required')"
                                                :value="DebitNote__gstrmrate"
                                                autocomplete="off"
                                                dense
                                                hide-details
                                                required
                                                solo
                                                type="number"
                                                @change="DebitNote__gstrmrate = $event"
                                            >
                                            </v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.printPreferences') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.attentionInformation') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items="buyerAndSupplierContacts"
                                                dense
                                                hide-details
                                                item-value="Contact.id"
                                                item-text="Contact.name"
                                                solo
                                                v-model="DebitNote__contact_id"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column pb-1">
                                                        <span class="font-weight-bold">{{(item.Contact.prefix != '' ? item.Contact.prefix + ' ' : '')  + item.Contact.name }}</span>
                                                        <span class="font-sm" v-if="item.Contact.position != null">{{ item.Contact.position }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-weight-bold">{{ (item.Contact.prefix ? item.Contact.prefix + " ": "") + item.Contact.name }}</span>
                                                    </div>
                                                </template>
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="false">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.forGpIntegration') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.gpTaxCode') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items="taxCodes"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                solo
                                                v-model="DebitNote__headertaxschedule"
                                            ></v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.gpCustomerNumber') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>{{ DebitNote__Company__GPCustomerNumber.trim().length > 0 ? DebitNote__Company__GPCustomerNumber.trim() : $t('message.gpCustomerNumberRequired') }}</v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_debitnote')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="debitnotes" :asset_id="DebitNote__id"></DocumentHistory>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
<!--                                    <v-btn-->
<!--                                        class="ml-3"-->
<!--                                        small-->
<!--                                        v-if="updateMode == true"-->
<!--                                        @click.stop="viewPdf( DebitNote__id )"-->
<!--                                    >-->
<!--                                        <v-icon small>far fa-file-pdf</v-icon>-->
<!--                                        <span class="ml-1">{{ $t('message.invoice') }}</span>-->
<!--                                    </v-btn>-->
<!--                                    <v-btn-->
<!--                                        class="ml-3"-->
<!--                                        small-->
<!--                                        v-if="updateMode == true"-->
<!--                                        @click.stop="viewTaxPdf( DebitNote__id )"-->
<!--                                    >-->
<!--                                        <v-icon small>far fa-file-pdf</v-icon>-->
<!--                                        <span class="ml-1">{{ $t('message.pdf') }}</span>-->
<!--                                    </v-btn>-->
                                    <PrintDebitNoteButton
                                        :currency="getCurrency(DebitNote__currency_id)"
                                        :update-mode="updateMode"
                                        :debit-note-id="DebitNote__id"
                                        :tax-mode="true"
                                        :list-mode="false"
                                    />
                                    <v-btn
                                        :loading="loading.reset"
                                        class="ml-3"
                                        color="default"
                                        small
                                        v-if="addMode"
                                        @click="resetDebitNoteForm()"
                                    >
                                        <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.add"
                                        class="ml-3"
                                        color="info"
                                        small
                                        @click="addDebitNote()"
                                        v-if="addMode == true"
                                    >{{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.save"
                                        class="ml-3"
                                        color="info"
                                        small
                                        @click="saveDebitNote()"
                                        v-if="updateMode == true && DebitNote__is_posted != 1"
                                    >{{ $t('message.save') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.cancel"
                                        class="ml-3"
                                        color="error"
                                        small
                                        v-if="updateMode == true && DebitNote__is_posted != 1"
                                        @click="cancelDebitNote( DebitNote__id )"
                                    >{{ $t('message.cancel') }}
                                    </v-btn>
<!--                                    <v-btn-->
<!--                                        :loading="loading.post"-->
<!--                                        class="ml-3"-->
<!--                                        small-->
<!--                                        v-if="updateMode == true && DebitNote__is_posted != 1"-->
<!--                                        @click="postToGp(DebitNote__id)"-->
<!--                                    >{{ $t('message.postToGp') }}-->
<!--                                    </v-btn>-->
                                    <v-btn
                                        :loading="loading.xero"
                                        class="ml-3"
                                        small
                                        v-if="updateMode == true && DebitNote__is_posted != 1"
                                        @click="uploadToXero(DebitNote__id)"
                                    >{{ $t('message.uploadToXero') }}
                                    </v-btn>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import {mapFields} from "vuex-map-fields";
    import {mapActions, mapGetters} from "vuex";
    import {formatDate, log, numberFormat, scrollToFirstFormValidationError} from "Helpers/helpers";
    import CommissionInvoiceAlert from "../../../components/Appic/CommissionInvoiceAlert";
    import SimpleAlert from "../../../components/Appic/SimpleAlert";
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import router from "@/router";
    import DocumentHistory from "Components/Appic/DocumentHistory";
    import {api} from "Api/index";
    import { v4 as uuidv4 } from 'uuid';
    import DebitNoteGeneralInfo from "Components/Appic/Loading/DebitNoteGeneralInfo";
    import DebitNoteCalculation from "Components/Appic/Loading/DebitNoteCalculation";
    import PrintDebitNoteButton from "Components/Appic/Printing/PrintDebitNoteButton";

    export default {
        name: "AddCommissionInvoice",
        components: {
            PrintDebitNoteButton,
            DebitNoteCalculation, DebitNoteGeneralInfo, DocumentHistory, CommissionInvoiceAlert},
        props: ['debitNoteId','contractId'],
        title: '',
        data() {
            return {
                buttons: {
                    resetCommissionInvoiceButton: {
                        loading: false
                    },
                    cancelCommissionInvoiceButton: {
                        loading: false
                    }
                },
                commissionInvoiceDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
                commissionInvoiceDateMenu: false,
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                documentContacts: [],
                taxRateTitles: {
                    1: this.$t('message.rmRateGstCalculation'),
                    3: this.$t('message.rmRateGstCalculation'),
                    21: this.$t('message.idrRateVatCalculation'),
                },
                loader: false,
                loading: {
                    contract: false,
                    add: false,
                    post: false,
                    reset: false,
                    debitNote: false,
                    save: false
                },
                loadingRelatedContract: false,
                panel: [0,1],
                rules: {
                    date: v => !!v || this.$t('message.required'),
                    debitNoteValue: v => !!v || this.$t('message.required'),
                    margin: v => !!v || this.$t('message.required'),
                    title: v => !!v || this.$t('message.required')
                },
                previewDialog: false,
                validForm: true,
                valueTypes: [
                    {value: 0, text: this.$t('message.showValueCif')},
                    {value: 1, text: this.$t('message.showValueCfr')},
                    {value: 2, text: this.$t('message.showValueFob')}
                ]
            }
        },
        computed: {
            statePrefix() {
                if (this.updateMode) {
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('debitnote',{
                buyerAndSupplierContacts: 'buyerAndSupplierContacts',
                DebitNote__id: 'DebitNote.id',
                DebitNote__amount: 'DebitNote.amount',
                DebitNote__bank_id: 'DebitNote.bank_id',
                DebitNote__cfrciffee: 'DebitNote.cfrciffee',
                DebitNote__comm_from_customer: 'DebitNote.comm_from_customer',
                DebitNote__contact_id: 'DebitNote.contact_id',
                DebitNote__containercount: 'DebitNote.containercount',
                DebitNote__containersize_id: 'DebitNote.containersize_id',
                DebitNote__contract_id: 'DebitNote.contract_id',
                DebitNote__currency_id: 'DebitNote.currency_id',
                DebitNote__dndate: 'DebitNote.dndate',
                DebitNote__dnvalue: 'DebitNote.dnvalue',
                DebitNote__etddate: 'DebitNote.etddate',
                DebitNote__fasvalue: 'DebitNote.fasvalue',
                DebitNote__fobfee: 'DebitNote.fobfee',
                DebitNote__freight: 'DebitNote.freight',
                DebitNote__gstpercent: 'DebitNote.gstpercent',
                DebitNote__gstrmrate: 'DebitNote.gstrmrate',
                DebitNote__headertaxschedule: 'DebitNote.headertaxschedule',
                DebitNote__invno: 'DebitNote.invno',
                DebitNote__is_posted: 'DebitNote.is_posted',
                DebitNote__mbfqty: 'DebitNote.mbfqty',
                DebitNote__mbffee: 'DebitNote.mbffee',
                DebitNote__office_id: 'DebitNote.office_id',
                DebitNote__posted: 'DebitNote.posted',
                DebitNote__posted_by: 'DebitNote.posted_by',
                DebitNote__title: 'DebitNote.title',
                DebitNote__valuetype: 'DebitNote.valuetype',
                DebitNote__Company__GPCustomerNumber: 'DebitNote.Company.GPCustomerNumber',
                DebitNote__Contract__id: 'DebitNote.Contract.id',
                DebitNote__Contract__bank_id: 'DebitNote.Contract.bank_id',
                DebitNote__Contract__commission_term_against_id: 'DebitNote.Contract.commission_term_against_id',
                DebitNote__Contract__commission_term_id: 'DebitNote.Contract.commission_term_id',
                DebitNote__Contract__commission_unit_price: 'DebitNote.Contract.commission_unit_price',
                DebitNote__Contract__currency_id: 'DebitNote.Contract.currency_id',
                DebitNote__Contract__customer_id: 'DebitNote.Contract.customer_id',
                DebitNote__Contract__incoterm_id: 'DebitNote.Contract.incoterm_id',
                DebitNote__Contract__loadingport_id: 'DebitNote.Contract.loadingport_id',
                DebitNote__Contract__margin: 'DebitNote.Contract.margin',
                DebitNote__Contract__office_id: 'DebitNote.Contract.office_id',
                DebitNote__Contract__partial_no: 'DebitNote.Contract.partial_no',
                DebitNote__Contract__revision_no: 'DebitNote.Contract.revision_no',
                DebitNote__Contract__shippingport_id: 'DebitNote.Contract.shippingport_id',
                DebitNote__Contract__title: 'DebitNote.Contract.title',
                DebitNote__Contract__version: 'DebitNote.Contract.version',
                DebitNote__Customer__company_id: 'DebitNote.Customer.company_id',
                DebitNote__Customer__id: 'DebitNote.Customer.id',
                DebitNote__Customer__title: 'DebitNote.Customer.title',
                DebitNote__Office__title: 'DebitNote.Office.title',
                DebitNote__Salescontact__name: 'DebitNote.Salescontact.name',
                DebitNote__Supplier__company_id: 'DebitNote.Supplier.company_id',
                DebitNote__Supplier__id: 'DebitNote.Supplier.id',
                DebitNote__Supplier__title: 'DebitNote.Supplier.title'
            }, 'statePrefix'),
            ...mapFields('debitnote',{
                newDebitNoteSaved: 'newDebitNoteSaved',
                newDebitNoteUnsaved: 'newDebitNoteUnsaved',
                updatedDebitNoteSaved: 'updatedDebitNoteSaved',
                updatedDebitNoteUnsaved: 'updatedDebitNoteUnsaved',
                allActiveDebitNotes: 'allActiveDebitNotes'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('buyer', {
                allBuyers: 'allBuyers',
                findBuyerById: 'findBuyerById'
            }),
            ...mapGetters('bankaccount', {
                allBankAccounts: 'allBankAccounts',
                findBankAccountById: 'findBankAccountById',
                findBankAccountsByOfficeAndCurrency: 'findBankAccountsByOfficeAndCurrency',
            }),
            ...mapGetters('contact',{
                getContactsByCompany: 'getContactsByCompany'
            }),
            ...mapGetters('contract',{
                getContractById: 'getContractById',
                allUnInvoicedCommissionContracts: 'allUnInvoicedCommissionContracts'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('user',{
                User: 'User'
            }),
            ...mapGetters([
                'containerSizes',
                'currencies',
                'taxCodes',
                'gstRates',
                'offices',
                'statuses'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_debitnote')
            },
            allowPosting: function () {
                return this.User.email == 'vanjoe@apptimber.com' //temporary
            },
            computedCommissionInvoiceDateFormatted () {
                return formatDate(this.DebitNote__dndate)
            },
            filteredBankAccounts: function(){
                if(this.DebitNote__Contract__office_id != null && this.DebitNote__Contract__currency_id != null){
                    return this.findBankAccountsByOfficeAndCurrency(this.DebitNote__Contract__office_id, this.DebitNote__Contract__currency_id)
                } else {
                    return this.allBankAccounts
                }
            },
            Invoice__bank_id: {
                get () {
                    return this.DebitNote__Contract__bank_id
                },
                set (val) {
                    this.DebitNote__Contract__bank_id = val
                }
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_debitnote')
            }
        },
        methods: {
            ...mapActions('bankaccount', {
                resetAllBankAccounts: 'resetAllBankAccounts',
                getAllBankAccounts: 'getAllBankAccounts'
            }),
            ...mapActions('contact',{
                getActiveContactsByCompany: 'getActiveContactsByCompany',
            }),
            ...mapActions('contract',{
                getAllActiveContracts: 'getAllActiveContracts',
                getAllClosedContracts: 'getAllClosedContracts'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('debitnote', {
                calculateCommission: 'calculateCommission',
                cancelDebitNoteById: 'cancelDebitNoteById',
                createDebitNote: 'createDebitNote',
                getAllActiveDebitNotes: 'getAllActiveDebitNotes',
                getDebitNoteToUpdateById: 'getDebitNoteToUpdateById',
                resetAllActiveDebitNotes: 'resetAllActiveDebitNotes',
                resetCurrentDebitNote: 'resetCurrentDebitNote',
                updateDebitNote: 'updateDebitNote'
            }),
            addDebitNote () {
                if(this.$refs.debitNoteForm.validate()) {
                    this.loading.add = true
                    this.createDebitNote()
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.debitNoteAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.add = false
                                this.newCommissionInvoiceUnsaved = false
                                this.newCommissionInvoiceSaved = true
                                this.resetCurrentDebitNote().then(() => {
                                    this.resetAllActiveDebitNotes().then(() => {
                                        router.push({
                                            name: 'update_debitnote',
                                            params: {debitNoteId: response.debitnote_id}
                                        }).catch(err => {
                                        })
                                    })
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.debitNoteNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.add = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.debitNoteNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        })
                        .finally(() => {
                            this.loading.add = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.debitNoteNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
            },
            async cancelDebitNote(val) {
                if(await this.$root.$confirm(this.$t('message.cancelDebitNote'), this.$t('message.confirmations.continueDebitNoteCancelAction'), {color: 'orange'})){
                    this.cancelDebitNoteById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.debitNoteDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            router.push({name: 'invoice_listing'}).catch(err => {})
                            this.loading.cancel = false
                        } else {
                            this.$toast.error(this.$t('message.errors.debitNoteNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                        .catch(()=>{
                            this.$toast.error(this.$t('message.errors.invoiceNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                }
            },
            console (val) {
                console.log(val)
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            findCurrencyPrecision (val) {
                let currency = this.allCurrencies.find(c => c.Currency.id == val)
                return currency?.Currency?.formatPrecision
            },
            formatDate,
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getCurrency (currency_id) {
                let code = this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
                return code !== undefined ? code : ''
            },
            loadAnotherActiveDebitNote (val) {
                router.push({name: 'update_debitnote', params: { debitNoteId : val}}).catch(err => {})
            },
            loadDebitNoteById ( val ) {
                this.loader = true
                this.getDebitNoteToUpdateById( val )
                    .then(()=>{
                        this.loader = false;
                        //get contacts for supplier and buyer
                        this.buyerAndSupplierContacts = []
                        if(this.DebitNote__Supplier__company_id != null) {
                            let promise = new Promise((resolve, reject) => {
                                api
                                    .get("/companies/" + this.DebitNote__Supplier__company_id + "/contacts")
                                    .then(response => {
                                        resolve(response.data.data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            })
                            promise.then(response => {
                                if(response.length > 0) {
                                    this.buyerAndSupplierContacts.push( {header: this.$t('message.supplierContacts')} )
                                    this.buyerAndSupplierContacts = this.buyerAndSupplierContacts.concat(response)
                                }
                            })
                        }
                        if(this.DebitNote__Customer__company_id != null) {
                            let promise = new Promise((resolve, reject) => {
                                api
                                    .get("/companies/" + this.DebitNote__Customer__company_id + "/contacts")
                                    .then(response => {
                                        resolve(response.data.data);
                                    })
                                    .catch(error => {
                                        reject(error);
                                    })
                            })
                            promise.then(response => {
                                if(response.length > 0) {
                                    this.buyerAndSupplierContacts.push( {header: this.$t('message.buyerContacts')} )
                                    this.buyerAndSupplierContacts = this.buyerAndSupplierContacts.concat(response)
                                }
                            })
                        }
                    })
                    .catch(()=>{
                        this.updatedCommissionInvoiceUnsaved = false
                    })
                    .finally(()=>{
                        this.updatedCommissionInvoiceUnsaved = false
                    })
            },
            loadUnInvoicedContract (val) {
                if(val != null) {
                    const contract = this.getContractById(val)
                    // log(contract)
                    this.DebitNote__Contract__id = val
                    this.DebitNote__Contract__bank_id = contract.Bank.id
                    this.DebitNote__Contract__margin = contract.Contract.margin
                    this.DebitNote__Contract__office_id = contract.Office.id
                    this.DebitNote__Customer__company_id = contract.Customer.company_id
                    this.DebitNote__Customer__id = contract.Customer.id
                    this.DebitNote__Customer__title = contract.Customer.title
                    this.DebitNote__Debitnote__contract_id = contract.Contract.id
                    this.DebitNote__Office__id = contract.Office.id
                    this.DebitNote__Office__title = contract.Office.title
                    this.DebitNote__Salescontact__name = contract.Salescontact.name
                    this.DebitNote__Supplier__company_id = contract.Supplier.company_id
                    this.DebitNote__Supplier__id = contract.Supplier.id
                    this.DebitNote__Supplier__title = contract.Supplier.title

                    //add missing fields
                    if(contract.Contract.commission_by_unit == 1){
                        this.DebitNote__Contract__commission_term_id = 3
                        this.DebitNote__Contract__commission_unit_price = contract.Contract.unit_commission
                    } else {
                        this.DebitNote__Contract__commission_term_id = contract.Contract.commission_term_id
                    }
                    this.DebitNote__Contract__commission_term_against_id = contract.Contract.commission_term_against_id
                    // this.DebitNote__Contract__commission_unit_price = contract.Contract.commission_unit_price

                    if(this.DebitNote__Contract__commission_term_id === 1) {
                        this.DebitNote__fobfee = contract.Contract.margin
                    }

                    this.DebitNote__contract_id = val
                    this.DebitNote__cfrciffee = contract.Contract.margin
                    this.DebitNote__currency_id = contract.Contract.currency_id
                    this.DebitNote__etddate = contract.Contract.etddate
                    this.DebitNote__office_id = contract.Office.id
                    this.DebitNote__supplier_id = contract.Contract.supplier_id
                    this.DebitNote__subsupplier_id = contract.Contract.subsupplier_id

                    let office = this.offices.find( o => o.Office.id === this.DebitNote__office_id)
                    this.DebitNote__headertaxschedule = office.Office.taxcode
                    this.DebitNote__gstpercent = office.Office.taxrate

                    //get contacts for supplier and buyer
                    this.buyerAndSupplierContacts = []
                    if(this.DebitNote__Supplier__company_id != null) {
                        let promise = new Promise((resolve, reject) => {
                            api
                                .get("/companies/" + this.DebitNote__Supplier__company_id + "/contacts")
                                .then(response => {
                                    resolve(response.data.data);
                                })
                                .catch(error => {
                                    reject(error);
                                })
                        })
                        promise
                            .then(response => {
                                if(response.length > 0) {
                                    this.buyerAndSupplierContacts.push( {header: this.$t('message.supplierContacts')} )
                                    this.buyerAndSupplierContacts = this.buyerAndSupplierContacts.concat(response)
                                    if(this.DebitNote__Contract__commission_term_against_id === 0){ //commission is against supplier
                                        this.DebitNote__contact_id = response[0]['Contact']['id']
                                    }
                                }
                                this.loading.contract = false
                            })
                            .catch(() => {
                                this.loading.contract = false
                            })
                    }
                    if(this.DebitNote__Customer__company_id != null) {
                        let promise = new Promise((resolve, reject) => {
                            api
                                .get("/companies/" + this.DebitNote__Customer__company_id + "/contacts")
                                .then(response => {
                                    resolve(response.data.data);
                                })
                                .catch(error => {
                                    reject(error);
                                })
                        })
                        promise
                            .then(response => {
                                if(response.length > 0) {
                                    this.buyerAndSupplierContacts.push( {header: this.$t('message.buyerContacts')} )
                                    this.buyerAndSupplierContacts = this.buyerAndSupplierContacts.concat(response)
                                    if(this.DebitNote__Contract__commission_term_against_id == 1){ //commission is against buyer
                                        this.DebitNote__contact_id = response[0]['Contact']['id']
                                    }
                                }
                                this.loading.contract = false
                            })
                            .catch(() => {
                                this.loading.contract = false
                            })
                    }
                } else {
                    this.DebitNote__Customer__title = null
                    this.DebitNote__Office__title = null
                    this.DebitNote__Salescontact__name = null
                }
            },
            async postToGp(val) {
                if (await this.$root.$confirm(this.$t('message.postToGpQueue'), this.$t('message.confirmations.continuePostToGpQueue'), {color: 'orange'})) {
                    this.loading.post = true
                    let promise = new Promise((resolve, reject) => {
                        api
                            .put("/debitnotes/" + val + "/post-gp")
                            .then(response => {
                                if (response.data.status == 'success') {
                                    resolve(response.data)
                                } else {
                                    resolve('error')
                                }
                            })
                            .catch(error => {
                                reject(error);
                            })
                    })
                    promise
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.debitNotePosted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.DebitNote__is_posted = response.is_posted
                                this.DebitNote__posted = response.posted
                                this.DebitNote__posted_by = response.posted_by
                                this.loading.post = false
                            } else {
                                this.$toast.error(this.$t('message.errors.debitNoteNotPosted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.post = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.debitNoteNotPosted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.post = false
                        })
                }
            },
            async resetDebitNoteForm () {
                if (await this.$root.$confirm(this.$t('message.resetDebitNoteForm'), this.$t('message.confirmations.continueDebitNoteFormReset'), {color: 'orange'})) {
                    this.loading.reset = true
                    this.resetCurrentDebitNote()
                        .then(() => {
                            this.newCommissionInvoiceSaved = false
                            this.newCommissionInvoiceUnsaved = false
                            this.loading.reset = false
                            this.$toast.success(this.$t('message.debitNoteFormResetNotification'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                        })
                        .catch(()=>{
                            this.loading.reset = false
                        })
                        .then(()=>{
                            this.loading.reset = false
                        })
                }
            },
            saveDebitNote () {
                if(this.$refs.debitNoteForm.validate()) {
                    this.loading.save = true
                    this.updateDebitNote()
                        .then((status) => {
                            if (status == 'done') {
                                this.$toast.success(this.$t('message.successes.debitNoteUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.save = false
                                this.updatedCommissionInvoiceUnsaved = false
                            } else {
                                this.$toast.error(this.$t('message.errors.debitNoteNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.save = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.debitNoteNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.debitNoteNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
            },
            updateCommission ( field, value) {
                this[field] = value
                if(field == 'DebitNote__Contract__commission_term_id'){
                    switch( value ){
                        case 0:
                            this.DebitNote__valuetype = 0
                            break
                        case 1:
                            this.DebitNote__valuetype = 2
                            break
                        case 2:
                            this.DebitNote__valuetype = 0
                            break
                    }
                }
                if(field == 'DebitNote__Contract__commission_unit_price'){
                    this['DebitNote__mbffee'] = value
                }
                this.calculateCommission( this.statePrefix )
            },
            async uploadToXero (val) {
                if (await this.$root.$confirm(this.$t('message.uploadToXeroQueue'), this.$t('message.confirmations.continueUploadToXeroAction'), {color: 'orange'})) {
                    this.loading.xero = true
                    let promise = new Promise((resolve, reject) => {
                        api
                            .put("/debitnotes/" + val + "/upload-xero")
                            .then(response => {
                                if (response.data.status == 'success') {
                                    resolve(response.data)
                                } else {
                                    resolve(response.data.message)
                                }
                            })
                            .catch(error => {
                                reject(error);
                            })
                            .finally(() => {
                                resolve('error')
                            })
                    })
                    promise
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.invoiceUploaded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.DebitNote__is_posted = 1
                                this.loading.xero = false
                            } else {
                                this.$toast.error(response,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.xero = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotUploaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.xero = false
                        })
                }
            },
            viewPdf(debitNoteId) {
                let contractNumber = [this.DebitNote__Contract__title, this.DebitNote__Contract__revision_no , this.DebitNote__Contract__partial_no].filter(Boolean).join('-')
                contractNumber = contractNumber.replace('.','')
                let document = encodeURIComponent(contractNumber + ' ' + this.DebitNote__title)
                // let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/debitnotes/print/'
                    + debitNoteId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewTaxPdf(debitNoteId) {
                let contractNumber = [this.DebitNote__Contract__title, this.DebitNote__Contract__revision_no , this.DebitNote__Contract__partial_no].filter(Boolean).join('-')
                contractNumber = contractNumber.replace('.','')
                let document = encodeURIComponent(contractNumber + ' ' + this.DebitNote__title)
                // let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/tax-debitnotes/print/'
                    + debitNoteId
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        watch: {
            // DebitNote__Contract__commission_term_id ( val ) {
            //     switch(val){
            //         case 0:
            //             this.DebitNote__valuetype = 0
            //             break
            //         case 1:
            //             this.DebitNote__valuetype = 2
            //             break
            //     }
            // },
            // DebitNote__Contract__id (val) {
            //     if(val != null) {
            //         let contract = this.getContractById(this.DebitNote__Contract__id)
            //         this.DebitNote__Contract__bank_id = contract.Bank.id
            //         this.DebitNote__Contract__office_id = contract.Office.id
            //         this.DebitNote__Customer__company_id = contract.Customer.company_id
            //         this.DebitNote__Customer__id = contract.Customer.id
            //         this.DebitNote__Customer__title = contract.Customer.title
            //         this.DebitNote__Debitnote__contract_id = contract.Contract.id
            //         this.DebitNote__Office__title = contract.Office.title
            //         this.DebitNote__Salescontact__name = contract.Salescontact.name
            //         this.DebitNote__Supplier__company_id = contract.Supplier.company_id
            //         this.DebitNote__Supplier__id = contract.Supplier.id
            //         this.DebitNote__Supplier__title = contract.Supplier.title
            //         this.DebitNote__currency_id = contract.Contract.currency_id
            //         this.DebitNote__cfrciffee = contract.Contract.margin
            //
            //         //get contacts for supplier and buyer
            //         this.buyerAndSupplierContacts = []
            //         if(this.DebitNote__Supplier__company_id != null) {
            //             let promise = new Promise((resolve, reject) => {
            //                 api
            //                     .get("/companies/" + this.DebitNote__Supplier__company_id + "/contacts")
            //                     .then(response => {
            //                         resolve(response.data.data);
            //                     })
            //                     .catch(error => {
            //                         reject(error);
            //                     })
            //             })
            //             promise.then(response => {
            //                 if(response.length > 0) {
            //                     this.buyerAndSupplierContacts.push( {header: this.$t('message.supplierContacts')} )
            //                     this.buyerAndSupplierContacts = this.buyerAndSupplierContacts.concat(response)
            //                 }
            //             })
            //         }
            //         if(this.DebitNote__Customer__company_id != null) {
            //             let promise = new Promise((resolve, reject) => {
            //                 api
            //                     .get("/companies/" + this.DebitNote__Customer__company_id + "/contacts")
            //                     .then(response => {
            //                         resolve(response.data.data);
            //                     })
            //                     .catch(error => {
            //                         reject(error);
            //                     })
            //             })
            //             promise.then(response => {
            //                 if(response.length > 0) {
            //                     this.buyerAndSupplierContacts.push( {header: this.$t('message.buyerContacts')} )
            //                     this.buyerAndSupplierContacts = this.buyerAndSupplierContacts.concat(response)
            //
            //                     console.log(this.buyerAndSupplierContacts)
            //                 }
            //             })
            //         }
            //     } else {
            //         this.DebitNote__Customer__title = null
            //         this.DebitNote__Office__title = null
            //         this.DebitNote__Salescontact__name = null
            //     }
            // },
            // Debitnote__comm_from_customer (val) {
            //
            // }
        },
        created() {
            // Resets
            // this.$store.dispatch('resetConstantsState')
            // this.$store.dispatch('contract/resetAllContracts')
            // this.$store.dispatch('contact/resetAllContacts')
            // Testing
            this.getAllClosedContracts()
            if(this.allActiveDebitNotes.length == 0) this.getAllActiveDebitNotes()
            if(this.allBankAccounts.length == 0) this.getAllBankAccounts()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()
            if(this.allUnInvoicedCommissionContracts.length == 0) {
                this.getAllActiveContracts()
            }

            if(this.$route.matched.some(({name}) => name === 'update_debitnote')) {
                this.loadDebitNoteById(this.debitNoteId)
            } else if(this.$route.matched.some(({name}) => name === 'add_debitnote')){
                this.newDebitNotesUnsaved = true;
                if(this.contractId != null){
                    this.loadUnInvoicedContract(this.contractId)
                }
            }
        },
        mounted() {
            if(this.$route.matched.some(({name}) => name === 'update_debitnote')){
                setInterval(() => {
                    this.$title =  "DN: " + this.DebitNote__title
                }, 2000)
            } else {
                this.$title = this.$t('message.titles.newDebitNote')
            }
        }
    }
</script>

<style>
    .font-xs {
        font-size: 0.65rem;
    }
</style>