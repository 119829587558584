<template>
    <div>
        <template v-if="updateMode && !listMode">
            <v-btn
                :loading="loading.debitNote"
                small
                class="ml-2 lighten-2"
                @click.stop="printDebitNote()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.pdf') }}</span>
            </v-btn>
            <v-btn
                :loading="loading.debitNoteNoTax"
                small
                class="ml-2 lighten-2"
                @click.stop="printDebitNoteNoTax()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ currency + ' ' + $t('message.only') }}</span>
            </v-btn>
        </template>
        <template v-if="updateMode && listMode">
            <v-list-item class="font-sm" @click.stop="printDebitNote()">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.debitNote"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.pdf') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printDebitNoteNoTax()">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.debitNoteNoTax"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.usdOnly') }}</span>
            </v-list-item>
        </template>
        <PrintDebitNoteBlobV2
            :debit-note-id="debitNoteId"
            :key="updateDebitNoteKey"
            :tax-mode="taxMode"
            @pdf-uploading="debitNoteUploading"
            @pdf-uploaded="debitNoteUploaded"
            @pdf-not-uploaded="debitNoteNotUploaded"
            v-if="hasDebitNote"
        />
        <PrintDebitNoteNoTaxBlobV2
            :debit-note-id="debitNoteId"
            :key="updateDebitNoteKey"
            :tax-mode="false"
            @pdf-uploading="debitNoteNoTaxUploading"
            @pdf-uploaded="debitNoteNoTaxUploaded"
            @pdf-not-uploaded="debitNoteNoTaxNotUploaded"
            v-if="hasDebitNoteNoTax"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {log, makeId} from "Helpers/helpers";

const PrintDebitNoteBlobV2 = () => import("Views/v1/debitnotes/PrintDebitNoteBlobV2");
const PrintDebitNoteNoTaxBlobV2 = () => import("Views/v1/debitnotes/PrintDebitNoteBlobV2");

export default {
    name: "PrintDebitNoteButton",
    props: ['debitNoteId','updateMode','listMode','taxMode','currency'],
    components: {PrintDebitNoteBlobV2, PrintDebitNoteNoTaxBlobV2},
    data() {
        return {
            currentDebitNotePdf: null,
            currentDebitNoteNoTaxPdf: null,
            hasDebitNote: false,
            hasDebitNoteNoTax: false,
            loading: {
                debitNote: false,
                debitNoteNoTax: false
            },
            updateDebitNoteKey: 1,
            updateDebitNoteNoTaxKey: 1
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdf() {
            return new Promise((resolve, reject) => {
                api
                    .get("/debitnotes/" + this.debitNoteId, {
                        params: {
                            fields: [
                                'debitnotes.location AS debitNotes__location',
                                'debitnotes.location_notax AS debitNotes__location_notax'
                            ]
                        }
                    })
                    .then(response => {
                        const debitNote = response.data.data[0]
                        if(debitNote){
                            this.currentDebitNotePdf = debitNote.DebitNote.location
                            this.currentDebitNoteNoTaxPdf = debitNote.DebitNote.location_notax
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        debitNoteNotUploaded() {
            this.loading.debitNote = false
            this.hasDebitNote = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        debitNoteNoTaxNotUploaded() {
            this.loading.debitNoteNoTax = false
            this.hasDebitNoteNoTax = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        debitNoteUploaded(url) {
            this.currentDebitNotePdf = url
            this.loading.debitNote = false
            this.hasDebitNote = false
            this.viewFile(this.currentDebitNotePdf)
        },
        debitNoteNoTaxUploaded(url) {
            this.currentDebitNoteNoTaxPdf = url
            this.loading.debitNoteNoTax = false
            this.hasDebitNoteNoTax = false
            this.viewFile(this.currentDebitNoteNoTaxPdf)
        },
        debitNoteUploading() {
            this.loading.debitNote = true
        },
        debitNoteNoTaxUploading() {
            this.loading.debitNoteNoTax = true
        },
        printDebitNote() {
            this.loading.debitNote = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentDebitNotePdf != null){
                        this.viewFile(this.currentDebitNotePdf)
                            .then(() => this.loading.debitNote = false)
                            .catch(() => this.loading.debitNote = false)
                    } else {
                        this.hasDebitNote = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.debitNote = false
                })
        },
        printDebitNoteNoTax() {
            this.loading.debitNoteNoTax = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentDebitNoteNoTaxPdf != null){
                        this.viewFile(this.currentDebitNoteNoTaxPdf)
                            .then(() => this.loading.debitNoteNoTax = false)
                            .catch(() => this.loading.debitNoteNoTax = false)
                    } else {
                        this.hasDebitNoteNoTax = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.debitNoteNoTax = false
                })
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        }
    }
}
</script>

<style scoped>

</style>